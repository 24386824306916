class ImageUploadField
  constructor: (field, options) ->
    @options  = $.extend

      thumbWidth: 200
      thumbHeight: null

    , options

    @field     = $ field
    @form      = @field.find 'form.new_image'
    @input     = @field.find 'input[type="file"]'
    @preview   = @field.find '.image-preview'
    @progress  = @field.find '.progress'
    @spinner   = $ '<i class="fa fa-sync icon-spin"/>'
    @image     = $ '<img/>'

    @initialize()

  initialize: ->
    @form.fileupload
      dataType: "script"

      add: (event, data) =>
        @add.apply @, arguments
        data.submit()

      progressall: =>
        @progressall.apply @, arguments

      done: =>
        @done.apply @, arguments

  done: ->
    @progress.hide()

  add: ->
    @preview.removeClass('hidden').addClass('loading').append @spinner
    @image.css 'opacity', .2
    @readImage()

  progressall: (event, data) ->
    progress = parseInt data.loaded / data.total * 100, 10
    @progress.show().find('.bar').css 'width', "#{progress}%"

  readImage: (input) ->
    if window.FileReader
      @preview.append @image

      reader = new FileReader()
      @image.css 'opacity', 0.4

      reader.onload = (evt) =>
        @image.attr 'src', evt.target.result
        @preview.show()
        @image.show()

      reader.readAsDataURL @input[0].files[0]

jQuery ->
  $( '.image-upload-field' ).each ->
    new ImageUploadField $(@)