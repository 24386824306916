import EXIF from 'exif-js'

class AssignmentLeadForm
  constructor: (selector) ->
    @form = $ selector
    @imageInput = @form.find 'input#assignment_lead_image'
    @imagePreview = @form.find 'input#lead-img-preview'
    @addressInput = @form.find 'input#assignment_lead_address'

    # Initialize geocomplete plugin
    @addressInput.geocomplete country: 'DK'

    # Store geocomplete and geocoder instances
    @geocomplete = @addressInput.data 'plugin_geocomplete'
    @geocoder = @geocomplete.geocoder

    @bindEvents()

  bindEvents: () ->
    @imageInput.on 'change', this.handleImageInputChange

  readAndApplyImageFile: (file) ->
    return unless 'FileReader' of window
    @imagePreview = $('#lead-img-preview')
    reader = new FileReader
    img = new Image()

    reader.addEventListener 'load', () =>
      @imageInput.addClass 'w-75'
      @imagePreview.removeClass 'hidden'
      @imagePreview.addClass 'w-25'
      @imagePreview.attr('src', reader.result)

    reader.readAsDataURL(file)

  handleImageInputChange: ({ target }) =>
    return unless target.files || target.files.length == 0
    $(target.files).each (key, file) =>
      @readAndApplyImageFile file
      # Convert Degrees, Minutes, Seconds to Decimal Degrees
      # (DMS to DD (dd = d + m/60 + s/3600))
      convert = (c) -> c[0] + (c[1]/60) + (c[2] / (60 * 60))
      applyAddress = @geocodeAndApplyResult
      setFromCurrentLocation = @setFromCurrentLocation

      EXIF.getData file, () ->
        latitude = EXIF.getTag(this, 'GPSLatitude')
        longitude = EXIF.getTag(this, 'GPSLongitude')
        latitudeRef = EXIF.getTag(this, 'GPSLatitudeRef')
        longitudeRef = EXIF.getTag(this, 'GPSLongitudeRef')

        if latitude and longitude
          latitude = convert(latitude)
          longitude = convert(longitude)

          longitude = -longitude if longitudeRef is 'W'
          latitude = -latitude if latitudeRef is 'S'

          applyAddress latitude, longitude
        else
          setFromCurrentLocation()

  setFromCurrentLocation: () =>
    return unless 'geolocation' of navigator

    navigator.geolocation.getCurrentPosition ({ coords }) =>
      @geocodeAndApplyResult coords.latitude, coords.longitude

  geocodeAndApplyResult: (lat, lng) =>
    location = { lat, lng }

    @geocoder.geocode { location }, (results) =>
      result = results[0]
      @addressInput.val(result.formatted_address) if result

initializeAssignmentLeadForm = () ->
  new AssignmentLeadForm('form')

window.initializeAssignmentLeadForm = initializeAssignmentLeadForm
