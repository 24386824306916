class Application
  constructor: ->
    @connectionError = $('<div class="alert alert-error"/>').text 'Det ser ud til at du ikke har forbindelse til internettet.'

    $ =>
      @initialize()

  initialize: ->
    @connectionError.append '<button type="button" class="close" data-dismiss="alert">&times;</button>'

  displayConnectionWarning: ->
    @connectionError.hide().prependTo('body>.container').slideDown()

  hideConnectionWarning: ->
    @connectionError.slideUp()

window.Kruger = new Application

$ ->
  # Init dropdown-toggles
  $('.dropdown-toggle').dropdown()

  # Scroll to top
  scrollToTop     = $( '<div class="scroll-to-top"/>' ).html 'Gå til toppen <i class="fa fa-chevron-up">'
  scrollerVisible = no


  scrollToTop.click ->
    $( window ).scrollTop 1

  $( window ).scroll ->
    if $( window ).scrollTop() > 1000 and scrollerVisible is false
      scrollToTop.appendTo('body').fadeIn()
      scrollerVisible = true
    else if scrollerVisible is true and $( window ).scrollTop() < 1000
      scrollToTop.fadeOut ->
        $(@).hide()
      scrollerVisible = false

  if typeof navigator.onLine isnt "undefined" and navigator.onLine is false
    Kruger.displayConnectionWarning()

  setTimeout ->
    window.document.body.ononline = ->
      Kruger.hideConnectionWarning()

    window.document.body.onoffline = ->
      Kruger.displayConnectionWarning()
  , 1000
