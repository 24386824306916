import EXIF from 'exif-js'

class SiteImageForm
  constructor: (selector) ->
    @form = $ selector
    @imageInput = @form.find 'input#site_image_image'
    @addressInput = @form.find 'input#site_image_address'
    @imageContainer = @form.find '.image-container'

    # Initialize geocomplete plugin
    @addressInput.geocomplete country: 'DK'

    # Store geocomplete and geocoder instances
    @geocomplete = @addressInput.data 'plugin_geocomplete'
    @geocoder = @geocomplete.geocoder

    # Hide image container initially if empty
    hasImage = @imageContainer.children().length > 0
    @imageContainer.toggle hasImage

    @bindEvents()

  bindEvents: () ->
    @imageInput.on 'change', this.handleImageInputChange

  readAndApplyImageFile: (file) ->
    return unless 'FileReader' of window
    reader = new FileReader()
    img = new Image()

    reader.addEventListener 'load', () ->
      img.src = reader.result

    img.addEventListener 'load', () =>
      @imageContainer.append(img).show()

    reader.readAsDataURL(file)

  handleImageInputChange: ({ target }) =>
    return unless target.files || target.files.length == 0
    $(target.files).each (key, file) =>
      @readAndApplyImageFile file
      convert = (c) -> c[0] + (c[1]/60) + (c[2] / (60 * 60))
      applyAddress = @geocodeAndApplyResult
      setFromCurrentLocation = @setFromCurrentLocation

      EXIF.getData file, () ->
        latitude = EXIF.getTag(this, 'GPSLatitude')
        longitude = EXIF.getTag(this, 'GPSLongitude')
        latitudeRef = EXIF.getTag(this, 'GPSLatitudeRef')
        longitudeRef = EXIF.getTag(this, 'GPSLongitudeRef')

        if latitude and longitude
          latitude = convert(latitude)
          longitude = convert(longitude)

          longitude = -longitude if longitudeRef is 'W'
          latitude = -latitude if latitudeRef is 'S'

          applyAddress latitude, longitude
        else
          setFromCurrentLocation()


  setFromCurrentLocation: () =>
    return unless 'geolocation' of navigator

    navigator.geolocation.getCurrentPosition ({ coords }) =>
      @geocodeAndApplyResult coords.latitude, coords.longitude

  geocodeAndApplyResult: (lat, lng) =>
    location = { lat, lng }

    @geocoder.geocode { location }, (results) =>
      result = results[0]
      @addressInput.val(result.formatted_address) if result

initializeSiteImageForm = () ->
  new SiteImageForm('form')

window.initializeSiteImageForm = initializeSiteImageForm
