# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/
jQuery ->

  $('.image-container a[data-remote="true"]').bind 'ajax:before', ->
    $(@).parent().find('img').css 'opacity', 0.5

  $('.image-container a[data-remote="true"]').bind 'ajax:complete', ->
    $(@).parent().find('img').css 'opacity', 1

  (->
    form            = $ '#new_note'
    progressElement = form.find '.progress'
    barElement      = progressElement.find '.bar'
    preview         = $ '.note-image-preview-container'
    image           = $ '<img/>'
    dataObject      = null

    preview.hide()

    uploadHandler   = (event, data) ->
      if dataObject?
        dataObject.submit()
        dataObject = null

    readImage       = (input) ->

      if window.FileReader

        $('.image-note-uploader').addClass 'w-75'
        $('.note-image-preview-container').addClass 'w-25'
        preview.show()

        preview.append image

        reader = new FileReader()

        reader.onload = (evt) =>
          image.attr 'src', evt.target.result
          preview.show()
          image.show()
          image.addClass("img-fluid")
          image.addClass("pr-3")

        reader.readAsDataURL input[0].files[0]


    form.bind "submit", (event) ->
      event.preventDefault()

      unless dataObject?
        $.ajax
          dataType: "script"
          url: "/notes"
          type: "post"
          data: form.serialize()

    form.fileupload
      dataType: "script"

      add: (event, data) ->
        dataObject = data

        readImage data.fileInput

        form.bind "submit", uploadHandler

      progressall: (event, data) ->
        progressElement.show() if progressElement.is ":hidden"

        progress = parseInt data.loaded / data.total * 100, 10
        progressElement.fadeIn()
        barElement.css 'width', "#{progress}%"

      done: (event, data) ->
        dataObject = null
        data.files = []
        image.remove()
        preview.hide()
        $('.image-note-uploader').removeClass 'w-75'
        $('.note-image-preview-container').removeClass 'w-25'
        barElement.css 'width', '0%'
        progressElement.hide() if progressElement.is ":visible"
  )()
